import { mergeStyleSets } from "@fluentui/react";

export const commonCardStyles = mergeStyleSets({
  card: {
    width: "322px",
    maxWidth: "none",
    border: "1px solid rgb(209, 209, 209)",
    background: "#FFF",
    overflow: "hidden",
    boxShadow: "0 2px 4px rgba(187, 187, 187, 5)",
  },
  cardDetailsContainer: {
    lineHeight: "24px",
    textDecoration: "none",
    textAlign: "left",
    textIndent: "0px",
    textTransform: "none",
    verticalAlign: "baseline",
    whiteSpace: "normal",
    wordSpacing: "0px",
    width: "316px",
    height: "30px",
    overflow: "hidden",
    cursor: "auto",
    visibility: "visible",
    transform: "none",
    transition: "all 0s ease 0s",
    boxSizing: "border-box",
    textOverflow: "clip",
    wordWrap: "normal",
    outline: "none",
    padding: "5px 0px 0px 0px"
  },
  LearningRoomIcon: {
    color: "black",
    marginRight: "10px",
    marginLeft: "8px",
    fontSize: "20px",
  },
  contentContainer: {
    textAlign: "left",
    height: "80px",
    overflow: "none",
  },
  scrollable: {
    width: "316px",
    height: "150px",
    padding: "3px 0px 0px 6px",
    overflow: "auto",
    outline: "none",
    cursor: "text",
    userSelect: "text",
  },
  languageScrollable: {
    width: "316px",
    height: "30px",
    padding: "5px 0px 0px 0px",
    overflow: "auto",
    outline: "none",
  },
  expertTitle: {
    width: "322px",
    height: "21px",
    marginTop:"5px",
    marginRight: "10px",
    marginLeft: "6px",
    fontWeight: "376",
    fontSize: "18px",
    textDecoration: "none solid rgb(51, 51, 51)",
  },
  expertImageContainer: {
    width: "58px",
    height: "58px",
    objectFit: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  expertImage: {
    width: "66%",
    height: "66%",
    borderRadius: "50%",
    cursor: "pointer",
  },
  learningExpertsProfileContainer: {
    marginTop: "10px",
    borderTop: "1px solid rgb(209, 209, 209)",
  },
  popoverSurface: {
    width: "300px",
    borderRadius: 0,
    padding: "0px",
    border: "none",
  },
  loginIconStyles: {
    padding: "2px",
  },
  expertLoginSection: {
    padding: "6px 0px 0px 0px",
  },
  expertLoginName: {
    textDecoration: "underline",
    marginLeft: "5px",
  },
  bioStyle: {
    padding: "5px 5px 5px 0px",
    marginLeft: "11px",
  },
});
