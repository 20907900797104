import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import MsHeader from "./components/MSHeaderAndFooter/MsHeader";
import MsFooter from "./components/MSHeaderAndFooter/MsFooter";
import { QueryClient, QueryClientProvider } from "react-query";
import LandingPage from "./components/LandingPage";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <MsHeader />
        <FluentProvider theme={webLightTheme}>
          <Router>
            <Routes>
              <Route path="/" element={<LandingPage/>} />
            </Routes>
          </Router>
        </FluentProvider>
        <MsFooter />
      </QueryClientProvider>
    </div>
  );
}

export default App;
