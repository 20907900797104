
import { mergeStyleSets } from "@fluentui/react";
export const commonStyles = mergeStyleSets({
  mainContainer: {
    maxWidth: "100%",
    width: "100%",
    margin: "0 auto",
    alignItems: "center",
    display: "block",
    transition: "all 0.3s ease",
  },
  container: {
    maxWidth: "1386px",
    width: "100%",
    margin: "0 auto",
    alignItems: "center",
    padding: "1%",
    "@media (max-width: 1399px)": {
            maxWidth: "1040px"
          },
    "@media (max-width: 1100px)": {
            maxWidth: "690px",
          },      
    "@media (max-width: 768px)": {
      maxWidth: "90%", 
    },
    "@media (max-width: 480px)": {
      maxWidth: "95%", 
    },
  },
  titleBanner: {
    textAlign: "left",
    whiteSpace: "normal",
    wordSpacing: "0px",
    height: "370px",
    maxWidth: "100%",
    width: "100%",
    border: "1px solid rgb(209, 209, 209)",
    margin: "10px auto 0 auto",
    boxSizing: "border-box",
    background:" linear-gradient(110deg, #1f3eac, #1f3eac,#3839a6, #4437a2, #59339d, #695db2, #7680c4)",
    "@media (max-width: 1100px)": {
      height: "auto",
    },
  },
  titleContentContainer: {
    display: "flex",
    justifyContent: "center",  
    "@media (max-width: 627px)":{
      flexDirection: "column-reverse",
    },
  },
  titleTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",  
    textAlign: "left",
    whiteSpace: "normal",
    wordSpacing: "0px",
    height: "auto",
    maxWidth: "50%",
    width: "50%",
    boxSizing: "border-box",
    margin: "auto 4% auto 4%",  
    "@media (max-width: 627px)":{
      maxWidth: "95%",
      width: "95%",
    },
    "@media (max-width: 1100px)": {
        padding: "20px 2% 20px 0px",
    },
  },
  subTitle: {
    fontSize: "25px",
    fontWeight: "400",
    lineHeight: "30px",
    color: "#FFFFFF",
    marginBottom: "20px",
    wordSpacing: "0px",
    maxWidth:"100%",
  },
  heading: {
    fontSize: "35px",
    fontWeight: "700",
    lineHeight: "30px",
    color: "#FFFFFF",
    marginBottom: "20px",
    wordSpacing: "0px",
    maxWidth:"100%",
  },
  subHeading1: {
    lineHeight: "20px",
    color: "#FFFFFF",
    width: "100%",
    maxWidth:"100%",
  },
  subHeading2: {
    fontSize: "14.4px",
    fontWeight: "490",
    lineHeight: "20px",
    margin: "0 auto",
    maxWidth: "100%",
    width: "100%",
  },
  otherContents: {
    textAlign: "left",
    whiteSpace: "normal",
    wordSpacing: "0px",
    height: "auto",
    maxWidth: "100%",
    width: "100%",
    alignItems: "left",
    display: "flex",
    justifyContent: "left",
    gap: "20px", 
    marginTop: "20px", 
    transition: "all 0.3s ease",
    "@media (max-width: 515px)": {
      flexDirection: "column",
      gap: "10px", 
    },
  },
  cardsContainer: {
    maxWidth: "100%",
    width: "100%",
    display: "grid",
    gridAutoRows: "auto",
    gridTemplateColumns: "repeat(4, 1fr)",
    justifyContent: "center",
    gap: "20px",
    transition: "all 0.3s ease",
    margin: "0 auto",
    alignItems: "center",
    marginTop: "20px",
    "@media (max-width: 1399px)": {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
    "@media (max-width: 1100px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
    "@media (max-width: 770px)": {
            gridTemplateColumns: "repeat(1, 1fr)",
          },
  },
  noDataFoundContainer:{
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  }
});
