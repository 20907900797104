import { FC, useEffect, useState } from "react";
import LearningRooms from "./LearningRooms/LearningRooms";
import TitleContent from "./TitleContent/TitleContent";
import { Stack, Spinner, SpinnerSize } from "@fluentui/react";
import { commonStyles } from "./CommonStyles/CommonStyles";

const LandingPage: FC = () => {
    const [selectedLocalizationLanguage, setSelectedLocalizationLanguage] = useState("en-US");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    useEffect(() => {
        const setSiteLanguage = async () => {
            setLoading(true);
            setSelectedLocalizationLanguage("en-US");
            setLoading(false);
        }
        setSiteLanguage();
    }, []);

    return (
        <>
            {loading ? (
                <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" />
            ) : (
                <Stack className={commonStyles.mainContainer}>
                    <Stack className={commonStyles.container} styles={{ root: { justifyContent: 'flex-end' } }}>
                        <TitleContent selectedLocalizationLanguage={selectedLocalizationLanguage} />
                        <LearningRooms selectedLocalizationLanguage={selectedLocalizationLanguage} />
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default LandingPage;
