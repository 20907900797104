import React, { useEffect, useState } from "react";
import { Stack, Text, Spinner, SpinnerSize, Image} from "@fluentui/react";
import { commonStyles } from "../CommonStyles/CommonStyles";
import { getLearningRoomPageData } from "../../services/LandingPageServices";
import { ITitleContentInfo } from "./ITitleContentInfo";
import bannerImage from "../../assets/community-hero-banner.png";


export type ILocalizationProps = {
  selectedLocalizationLanguage: string;
};

const TitleContent: React.FC<ILocalizationProps> = (props) => {
  const { selectedLocalizationLanguage } = props;
  const localizationLanguageChosen = selectedLocalizationLanguage as string;
  const [learningRoomPageData, setLearningRoomPageData] = useState<ITitleContentInfo[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLearningRoomPageData = async () => {
      setLoading(true);
      try {
        const learningRoomPageDataResponse = await getLearningRoomPageData(localizationLanguageChosen);
        setLearningRoomPageData(learningRoomPageDataResponse);
      } catch (error) {
        console.error("Error fetching learning room page details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchLearningRoomPageData();
  }, [localizationLanguageChosen]);

  const data = learningRoomPageData[0];

  return (
    <>
      <Stack className={commonStyles.titleBanner} styles={{ root: { display: 'flex', justifyContent: 'center', alignItems: 'center' } }}>
        {loading ? (
          <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" />
        ) : (
          <Stack horizontal className={commonStyles.titleContentContainer}>
          <Stack className={commonStyles.titleTextContainer}>
           <Text className={commonStyles.subTitle} tabIndex={0}>{data?.subTitle} </Text>
            <Text className={commonStyles.heading} tabIndex={0}>{data?.title} </Text>
            <Text className={commonStyles.subHeading1} tabIndex={0}>{data?.content1}</Text>
          </Stack>
          <Stack verticalAlign="center">
          <Image src={bannerImage} alt="Banner Image" style={{ width: '100%', maxWidth: '500px', height: '100%' }} />
          </Stack>
          </Stack>
        )}
      </Stack>
      {!loading && (
        <Stack className={commonStyles.otherContents} tabIndex={0}>
          <Text className={commonStyles.subHeading2}>{data?.content2}</Text>
        </Stack>
      )}
    </>
  );
};

export default TitleContent;