
import React, { useState, useEffect } from "react";
import {
  Stack,
  Icon,
  mergeStyleSets,
  DocumentCardPreview,
  DocumentCard,
  StackItem,
  Text,
} from "@fluentui/react";
import TitleForCard from "./TitleForCard";
import { commonCardStyles } from "./CardStyles";
import { getNewCardPreviewProps } from "./CardImage";
import { ILearningRoomsInfo } from "../LearningRooms/ILearningRoomsInfo";
import {
  TeachingPopover,
  TeachingPopoverTrigger,
  TeachingPopoverSurface,
  TeachingPopoverHeader,
  TeachingPopoverBody,
} from "@fluentui/react-components";
export type ICardProps = {
  cardData: ILearningRoomsInfo;
};
 
const cardStyles = {
  popoverSurface: {
    width: "300px",
    borderRadius: 0,
    padding: "0px",
    border: "none",
  },
};
const styles = mergeStyleSets(cardStyles, commonCardStyles);
const LearningRoomCard: React.FC<ICardProps> = (props) => {
  const { cardData } = props;
  const LRCardData = cardData as ILearningRoomsInfo;
  const defaultAvatarIcon = "https://cdn.techcommunity.microsoft.com/assets/default-avatar.png";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const expertImages = LRCardData.experts
    ? LRCardData.experts.map((expert, index) => (
<TeachingPopover
  key={index}
  openOnHover={windowWidth > 767}
  closeOnScroll={true}
  mouseLeaveDelay={0}
  withArrow={false}
  positioning={"above-start"}
>
  <TeachingPopoverTrigger>

      {windowWidth < 767 ? 
        <div className={styles.expertImageContainer}>
          <img
            className={styles.expertImage}
            src={expert.profileAvatar}
            alt={`Expert ${index}`}
            aria-label={`user avatar icon of ${expert.name}`}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = defaultAvatarIcon;
            }}
            style={
              expert.rankColor === "0078D4" ||
              expert.rankColor === "107C10" ||
              expert.rankColor === "9D41C3"
                ? { border: `3px solid #${expert.rankColor}` }
                : {}
            }         
             />
        </div>
        :
      <div className={styles.expertImageContainer}>
        <img
          onClick={() => { window.location.href = expert.viewHref; }}
          className={styles.expertImage}
          src={expert.profileAvatar}
          alt={`Expert ${index}`}
          aria-label={`User avatar icon of ${expert.name}`}
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = defaultAvatarIcon;
          }}
          style={
            expert.rankColor === "0078D4" ||
            expert.rankColor === "107C10" ||
            expert.rankColor === "9D41C3"
              ? { border: `3px solid #${expert.rankColor}` }
              : {}
          }
         />
      </div>
      }
  </TeachingPopoverTrigger>
  <TeachingPopoverSurface
    style={{
      ...cardStyles.popoverSurface,
      outline: `1px solid #${expert.rankColor}`,
    }}
  >
    {windowWidth < 767 && (
      <TeachingPopoverHeader 
        style={{
          background: `#${expert.rankColor}`, 
          outline:"none", 
          height: "19px", 
          paddingTop:"2px",
          paddingRight:"2px"
        }} 
        icon={null}
      >
      </TeachingPopoverHeader>
    )}
    <TeachingPopoverBody style={{paddingBottom: 0}}>
      <Stack
        horizontal
        styles={{
          root: {
            padding: "4px",
            backgroundColor: "#" + expert.rankColor,
            color: "White",
            height: "90px",
          },
        }}
      >
        <StackItem>
          <a
            href={expert.viewHref}
            style={{
              all: "unset",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "58px",
              height: "58px",
              borderRadius: "50%",
            }}
          >
            <div className={styles.expertImageContainer}>
              <img
                className={styles.expertImage}
                src={expert.profileAvatar}
                alt={`Expert ${index}`}
                aria-label={`user avatar icon of ${expert.name}`}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = defaultAvatarIcon;
                }}
                style={
                  expert.rankColor === "0078D4" ||
                  expert.rankColor === "107C10" ||
                  expert.rankColor === "9D41C3"
                    ? { border: `3px solid #${expert.rankColor}` }
                    : {}
                }
              />
            </div>
          </a>
        </StackItem>
        <StackItem>
          <div>
            <b>{expert.name}</b>
          </div>
          <div>{expert.rankName}</div>
          <Stack horizontal className={styles.expertLoginSection}>
            <Icon
              iconName="ContactInfo"
              className={styles.loginIconStyles}
            />
            <a
              href={expert.viewHref}
              style={{
                all: "unset",
                cursor: "pointer",
                display: "flex",
              }}
            >
              <div className={styles.expertLoginName}>
                @{expert.login}
              </div>
            </a>
          </Stack>
        </StackItem>
      </Stack>
      {expert.bio && (
        <div className={styles.bioStyle}>{expert.bio}</div>
      )}

    </TeachingPopoverBody>
  </TeachingPopoverSurface>
</TeachingPopover>
      ))
    : null;
  return (
    <DocumentCard className={styles.card}>
      <DocumentCardPreview {...getNewCardPreviewProps(LRCardData)} />
      <Stack>
        <TitleForCard cardData={LRCardData} />
        <Stack horizontalAlign="center" className={styles.contentContainer}>
          <Text className={styles.scrollable}>
            <div 
            tabIndex={0}
            aria-label={`Description ${LRCardData.description}`}
            >
              {LRCardData.description}</div>
          </Text>
        </Stack>
        <Stack>
          <Stack horizontal className={styles.cardDetailsContainer}>
            <Icon iconName="POI" className={styles.LearningRoomIcon} />
            <Text aria-label={`Location ${LRCardData.location}`} tabIndex={0}>{LRCardData.location} </Text>
          </Stack>
          <Stack horizontal className={styles.cardDetailsContainer}>
            <Icon iconName="CubeShape" className={styles.LearningRoomIcon} />
            <Text tabIndex={0} aria-label={`Product ${LRCardData.product}`}>{LRCardData.product}</Text>
          </Stack>
          <Stack horizontal className={styles.languageScrollable}>
            <Icon iconName="Globe" className={styles.LearningRoomIcon} />
            <Text aria-label={`Languages ${LRCardData.language}`} tabIndex={0}>{LRCardData.language}</Text>
          </Stack>
        </Stack>
        <Stack className={styles.learningExpertsProfileContainer}>
          <StackItem>
           <Text
           className={styles.expertTitle}
           aria-label={`List of Learning Experts `}
           tabIndex={0}>Learning Experts</Text> 
            </StackItem>
          <Stack horizontal wrap>
            {expertImages}
          </Stack>
        </Stack>
      </Stack>
    </DocumentCard>
  );
};
export default LearningRoomCard;
