import React, { useEffect, useState } from "react";
import { Stack, mergeStyleSets, StackItem, Spinner, SpinnerSize } from "@fluentui/react";
import LearningRoomCard from "../Cards/LearningRoomCard";
import { commonStyles } from "../CommonStyles/CommonStyles";
import {
  getFilterByProduct,
  getFilterByLocation,
  getFilterByLanguage,
  getLearningRoomCardData,
} from "../../services/LandingPageServices";

interface IProduct {
  id: number;
  product: string;
}
interface ILanguage {
  id: number;
  language: string;
}
interface ILocation {
  id: number;
  location: string;
}


const styles = mergeStyleSets({
  filterItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    transition: "all 0.3s ease",
    width: "200px", 
    "@media (max-width: 515px)": {
      width: "200px", 
    },
  },
  filterLabel: {
    marginBottom: "5px",
  },
  filterSelect: {
    width: "100%", 
    height: "30px",
    transition: "border-color 0.3s ease",
    ":focus": {
      outline: "none",
      borderColor: "#0078d4",
      boxShadow: "0 0 5px rgba(0, 120, 212, 0.5)",
    },
  },
});
export type ILocalizationProps = {
  selectedLocalizationLanguage: string;
};

const LearningRooms: React.FC<ILocalizationProps> = (props) => {
  const { selectedLocalizationLanguage } = props;

  const [selectedLocation, setSelectedLocation] = useState("All");
  const [locationFilters, setLocationFilters] = useState<ILocation[]>([]);
  const [locationFilterTitle, setLocationFilterTitle] = useState("");

  const [selectedProduct, setSelectedProduct] = useState("All");
  const [productFilters, setProductFilters] = useState<IProduct[]>([]);
  const [productFilterTitle, setProductFilterTitle] = useState("");

  const [selectedLanguage, setSelectedLanguage] = useState("All");
  const [languageFilters, setLanguageFilters] = useState<ILanguage[]>([]);
  const [languageFilterTitle, setLanguageFilterTitle] = useState("");

  const [learningRoomCardData, setLearningRoomCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(true);

  const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLocation(event.target.value);
  };
  const handleProductChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProduct(event.target.value);
  };
  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLanguage(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          locationFiltersResponse,
          productFiltersResponse,
          languageFiltersResponse,
          learningRoomCardDataResponse
        ] = await Promise.all([
          getFilterByLocation(selectedLocalizationLanguage),
          getFilterByProduct(selectedLocalizationLanguage),
          getFilterByLanguage(selectedLocalizationLanguage),
          getLearningRoomCardData(selectedLocalizationLanguage)
        ]);
  
        // Sorting products
        let sortedProducts = [...productFiltersResponse[0].products];
        sortedProducts.unshift({id: -1, product: "All"}); 
        sortedProducts.sort((a, b) => {
          if (a.product === "All") return -1;
          if (b.product === "All") return 1;
          return a.product.localeCompare(b.product);
        });
  
        // Sorting languages
        let sortedLanguages = [...languageFiltersResponse[0].languages];
        sortedLanguages.unshift({id: -1, language: "All"});
        sortedLanguages.sort((a, b) => {
          if (a.language === "All") return -1;
          if (b.language === "All") return 1;
          return a.language.localeCompare(b.language);
        });
  
        // Sorting locations
        let sortedLocations = [...locationFiltersResponse[0].locations];
        sortedLocations = sortedLocations.filter(location => location.location !== "Global");
        sortedLocations.unshift({id: -1, location: "All"});
        sortedLocations.unshift({id: -2, location: "Global"});
        sortedLocations.sort((a, b) => {
          if (a.location === "All") return -1;
          if (b.location === "All") return 1;
          if (a.location === "Global") return -1;
          if (b.location === "Global") return 1;
          return a.location.localeCompare(b.location);
        });
  
        if (
          locationFiltersResponse.length > 0 &&
          sortedProducts.length > 0 &&
          sortedLanguages.length > 0 &&
          sortedLocations.length > 0 &&
          learningRoomCardDataResponse.length > 0
        ) {
          setLocationFilters(sortedLocations);
          setLocationFilterTitle(locationFiltersResponse[0].filterTitle);
          setProductFilters(sortedProducts);
          setProductFilterTitle(productFiltersResponse[0].filterTitle);
          setLanguageFilters(sortedLanguages);
          setLanguageFilterTitle(languageFiltersResponse[0].filterTitle);
          setLearningRoomCardData(learningRoomCardDataResponse);
          setHasData(true);
        } else {
          setHasData(false);
        }
      } catch {
        setHasData(false);
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedLocalizationLanguage]);
  return (
    <>
      {loading ? (
        <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" />
      ) : hasData ? (
        <>
          <Stack horizontal className={commonStyles.otherContents}>
            <StackItem className={styles.filterItem}>
              <div id="location-filter-label" className={styles.filterLabel}>{locationFilterTitle}</div>
              <select
                aria-labelledby="location-filter-label"
                value={selectedLocation}
                onChange={handleLocationChange}
                className={styles.filterSelect}
              >
                {locationFilters?.map((location: any) => (
                  <option key={location.id} value={location.location}>
                    {location.location}
                  </option>
                ))}
              </select>
            </StackItem>
            <StackItem className={styles.filterItem}>
              <div id="product-filter-label" className={styles.filterLabel}>{productFilterTitle}</div>
              <select
                aria-labelledby="product-filter-label"
                value={selectedProduct}
                onChange={handleProductChange}
                className={styles.filterSelect}
              >
                {productFilters?.map((product: any) => (
                  <option key={product.id} value={product.product}>
                    {product.product}
                  </option>
                ))}
              </select>
            </StackItem>
            <StackItem className={styles.filterItem}>
              <div id="language-filter-label" className={styles.filterLabel}>{languageFilterTitle}</div>
              <select
                aria-labelledby="language-filter-label"
                value={selectedLanguage}
                onChange={handleLanguageChange}
                className={styles.filterSelect}
              >
                {languageFilters?.map((lang: any) => (
                  <option key={lang.id} value={lang.language}>
                    {lang.language}
                  </option>
                ))}
              </select>
            </StackItem>
          </Stack>

          <Stack>
            <Stack horizontal className={commonStyles.cardsContainer}>
              {learningRoomCardData.map((cardData: any, index: number) =>
                ((selectedLocation === "All" ||
                  cardData.location.trim().toUpperCase() === selectedLocation.trim().toUpperCase()) &&
                (selectedProduct === "All" ||
                  cardData.product.trim().toUpperCase() === selectedProduct.trim().toUpperCase()) &&
                (selectedLanguage === "All" ||
                  cardData.language.trim().toUpperCase().includes(selectedLanguage.trim().toUpperCase()))) ? (
                  <Stack key={index}>
                    <LearningRoomCard cardData={cardData} />
                  </Stack>
                ) : null
              )}
            </Stack>
          </Stack>
        </>
      ) : (
        <div className={commonStyles.noDataFoundContainer}>Currently no data found.<br />Please try again later.</div>
      )}
    </>
  );
};

export default LearningRooms;
