import { IDocumentCardPreviewProps, ImageFit } from "@fluentui/react";
import { ILearningRoomsInfo} from "../LearningRooms/ILearningRoomsInfo";


export const getNewCardPreviewProps = (cardData: ILearningRoomsInfo) => {
  const previewProps: IDocumentCardPreviewProps = {
    previewImages: [
      {
        linkProps: {
          href: cardData.imageUrl,
          target: "_blank",
        },
        previewImageSrc: cardData.imageUrl,
        iconSrc: "",
        imageFit: ImageFit.cover,
        height: 196,
      },
    ],
  };
  return previewProps;
};
