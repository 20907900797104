import { Link } from "@fluentui/react";
import { ILearningRoomsInfo } from "../LearningRooms/ILearningRoomsInfo";

type ITitleForCardProps = {
  cardData: ILearningRoomsInfo;
};

const TitleForCard: React.FC<ITitleForCardProps> = (props) => {
  const { cardData } = props;
  return (
    <div style={{ padding: "0px 8px 0px", height: "72px", overflow: "hidden" }}>
      <Link 
        href={cardData.roomUrl} 
        aria-label={`Learning Room Card Title ${cardData.title}`}
        target="_blank" 
        styles={{
          root: {
            lineHeight: "24px",
            textDecoration: "underline",
            fontSize: "18px",
            color: "#0072cc",
            fontWeight: "400",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "3",
            display: "-webkit-box",
            overflow: "hidden",
            transition: "color 0.3s ease",
            selectors: {
              ':hover': {
                color: "navy",
                cursor: "pointer",
              },
            },
          },
        }}
      >
          {cardData.title}
      </Link>
    </div>
  );
};

export default TitleForCard;
