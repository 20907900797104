import axiosClient from "../utilities/HttpClient";
export const getUHF = async () => {
  try {
    const result = await fetch(
      "https://uhf.microsoft.com/en-us/shell/xml/MSLearnings?headerid=MSLearningsHeader&footerid=MSLearningsFooter"
    );
    const data = await result.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, "text/xml");
    return doc;
  } catch (error: any) {
    console.log(error);
  }
};
const getCachedData = (key: string) => {
  const cachedData = localStorage.getItem(key);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    const currentTime = new Date().getTime();
    const cacheDuration = 24 * 60 * 60 * 1000;
    if (currentTime - timestamp < cacheDuration) {
      return data;
    } else {
      localStorage.removeItem(key);
    }
  }
  return null;
};
const setCachedData = (key: string, data: any) => {
  const timestamp = new Date().getTime();
  const cacheData = JSON.stringify({ data, timestamp });
  localStorage.setItem(key, cacheData);
};

export const getLearningRoomCardData = async (localizedLanguage: string) => {
    const cachedData = getCachedData("learningRoomCardData_" + localizedLanguage);
    if (cachedData) {
      return cachedData;
    } else {
      try {
      const response = await axiosClient.get("BlobConfig/learningRoomCardContent/" + localizedLanguage);
      const learningRoomCardData = response.data;
      if (learningRoomCardData) {
        setCachedData("learningRoomCardData_" + localizedLanguage, learningRoomCardData);
      }
      return learningRoomCardData;
    }
      catch (error) {
        return null; // Return null or handle as needed
      }
    }
};
 
export const getLearningRoomPageData = async (localizedLanguage:string) => {
  const cachedData = getCachedData("learningRoomPageData_"+localizedLanguage);
  if (cachedData) return cachedData;
  else{
        const response = await axiosClient.get("BlobConfig/learningRoomPageContent/"+localizedLanguage);
        const learningRoomPageData = response.data;
        learningRoomPageData && setCachedData("learningRoomPageData_"+localizedLanguage,learningRoomPageData);
        return learningRoomPageData;
  }
}
export const getFilterByProduct = async (localizedLanguage:string) => {
  const cachedData = getCachedData("filterByProduct_"+localizedLanguage);
  if (cachedData) return cachedData;
  else{
        const response = await axiosClient.get("BlobConfig/learningRoomFilterByProduct/"+localizedLanguage);
        const filterByProduct = response.data;
        filterByProduct && setCachedData("filterByProduct_"+localizedLanguage,filterByProduct);
        return filterByProduct;
  }
}
 
export const getFilterByLanguage = async (localizedLanguage:string) => {
  const cachedData = getCachedData("filterByLanguage_"+localizedLanguage);
  if (cachedData) return cachedData;
  else{
        const response = await axiosClient.get("BlobConfig/learningRoomFilterByLanguage/"+localizedLanguage);
        const filterByLanguage = response.data;
        filterByLanguage && setCachedData("filterByLanguage_"+localizedLanguage,filterByLanguage);
        return filterByLanguage;
  }
}
 
 
export const getFilterByLocation = async (localizedLanguage:string) => {
  const cachedData = getCachedData("filterByLocation_"+localizedLanguage);
  if (cachedData) return cachedData;
  else{
  const response = await axiosClient.get("BlobConfig/learningRoomFilterByLocation/"+localizedLanguage);
  const filterByLocation = response.data;
  filterByLocation && setCachedData("filterByLocation_"+localizedLanguage,filterByLocation);
  return filterByLocation;
  }
}
 
